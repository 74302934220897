import { CheckOutlined, DownOutlined, FileExcelOutlined, CopyOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Drawer,
    Dropdown,
    message,
    PageHeader,
    Radio,
    RadioChangeEvent,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import scrollIntoView from 'scroll-into-view';
import authService from '../../components/api-authorization/AuthorizeService';
import { DRAWER_WIDTH } from '../../config/constants';
import { defaultTablePagination } from '../../config/constants';
import { defaultFormat } from '../../config/constants';
import { translations } from '../../config/translations';
import { getDaysInMonthUTC, getYears } from '../../helpers/DateHelper';
import { downloadFile } from '../../helpers/FileHelper';
import { getDefaultFilter, getSearchFilter } from '../../helpers/FilterHelper';
import { showConfirm, showError, showSuccess } from '../../helpers/NotificationHelper';
import { formatPrice } from '../../helpers/PriceHelper';
import { isVisible, sumPropValues } from '../../helpers/ProjectItemHelper';
import { getDefaultComparer } from '../../helpers/SortHelper';
import { truncateString } from '../../helpers/StringHelper';
import { getTableLocale } from '../../helpers/TableHelper';
import {
    ApiException,
    EmployeesClient,
    ExportWorkHourRecordsByDayXlsxCommand,
    FileResponse,
    ProjectsClient,
    ProjectVm,
    RoleEnum,
    WorkHourRecordsClient,
    WorkHourRecordVm,
    WorkHourTypeEnum,
    HolidaysClient,
    HolidayVm,
    ExportWorkHourRecordsByProjectsXlsxCommand,
} from '../../utils/api';
import { Props } from './index';
import WorkHourRecordForm from './work-hour-record-form';
import WorkHourRecordsCopyForm from './work-hour-records-copy-form';
import {
    mapEmployeesToTableEmployees,
    mapProjectEmployeesToTableEmployees,
} from '../../helpers/FetchAndTransformHelpers';

enum ViewTypeEnum {
    Hours,
    Accommodation,
    Food,
    Gear,
}

export interface TableEmployeeVm {
    id: number;
    name: string;
    surname: string;
    fullName: string;
    activeTo?: Date | undefined;
}

interface State {
    employees?: TableEmployeeVm[];
    projects: ProjectVm[];
    records?: WorkHourRecordVm[];
    selectedProjectId?: number;
    selectedYear: number;
    selectedMonth: number;
    selectedView: ViewTypeEnum;
    showSummary: boolean;
    showInactiveEmployees: boolean;
    editingRecord?: WorkHourRecordVm;
    loading: number;
    userProfile?: any;
    holidays?: HolidayVm[];
    recordCopyDrawerOpen?: boolean;
}

const MIN_YEAR = 2022;
const TODAY_CLASS = 'current-date';
const SCROLLABLE_CONTAINER_CLASS = 'ant-table-body';
const NON_TABLE_CONTENT_HEIGHT = 380;
const initialEntryDeadline = 2;

export class WorkHourRecordsTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            employees: props.employees,
            projects: props.project ? [props.project] : [],
            selectedProjectId: props.project ? props.project.id : 0,
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth(),
            selectedView: ViewTypeEnum.Hours,
            showSummary: false,
            showInactiveEmployees: false,
            loading: 0,
        };
    }

    componentDidMount = () => {
        const { project } = this.props;

        if (!project) {
            this.getProjects();
            this.getEmployees();
        }

        this.getRecords();
        this.getHolidays();
        this.getUserProfile();
    };

    async getUserProfile() {
        this.setState({ userProfile: await authService.getUserProfile() });
    }

    componentDidUpdate = (_: Props, prevState: State) => {
        const { records } = this.state;

        if (records && !prevState.records) {
            this.scrollToToday();
        }
    };

    private increaseLoader = () => {
        this.setState((prevState: State) => ({
            loading: prevState.loading + 1,
        }));
    };

    private decreaseLoader = () => {
        this.setState((prevState: State) => ({
            loading: prevState.loading - 1,
        }));
    };

    private getProjects = async () => {
        this.increaseLoader();

        this.setState({
            projects: await new ProjectsClient().getAll(),
        });
        this.decreaseLoader();
    };

    private getEmployees = async (projectId?: number) => {
        this.increaseLoader();

        if (!projectId) {
            this.setState({
                employees: mapEmployeesToTableEmployees(await new EmployeesClient().getAll()),
            });
        } else {
            this.setState({
                employees: mapProjectEmployeesToTableEmployees(
                    await new ProjectsClient().getProjectEmployees(projectId)
                ),
            });
        }
        this.decreaseLoader();
    };

    private getRecords = async () => {
        const { selectedYear, selectedMonth } = this.state;

        this.increaseLoader();

        this.setState({
            records: await new WorkHourRecordsClient().getWorkHourRecords(
                null,
                null,
                selectedYear,
                selectedMonth + 1
            ),
        });

        this.decreaseLoader();
    };

    private handleProjectChange = async (projectId: number) => {
        this.setState({
            selectedProjectId: projectId,
        });

        this.getEmployees(projectId);
    };

    private scrollToToday = () => {
        const todayColumn = document.querySelector<HTMLElement>(`td.${TODAY_CLASS}`);
        const container = document.querySelector<HTMLElement>(`.${SCROLLABLE_CONTAINER_CLASS}`);

        if (!todayColumn) return;

        if (isVisible(todayColumn, container)) return;

        scrollIntoView(todayColumn, {
            align: {
                left: 0.5,
            },
        });
    };

    private goToToday = () => {
        this.setState(
            {
                selectedYear: new Date().getFullYear(),
                selectedMonth: new Date().getMonth(),
            },
            () => this.scrollToToday()
        );
    };

    private resetTableScroll = () => {
        const container = document.querySelector<HTMLElement>(`.${SCROLLABLE_CONTAINER_CLASS}`);

        if (!container) return;

        container.scrollLeft = 0;
    };

    private handleEdit = async (employeeId: number, date: Date) => {
        const { records, selectedProjectId, selectedMonth, selectedYear } = this.state;

        const record = records?.find(
            (r: WorkHourRecordVm) =>
                r.employeeId === employeeId && moment(r.date).isSame(date, 'day')
        );

        if (this.isEditWorkHourLocked(selectedMonth, selectedYear)) {
            message.warning(translations.workHourRecods.deadlineExpiredMessage);
            return;
        }

        if (record && !this.isOwnedRecord(record)) {
            message.warning(translations.workHourRecods.unauthorizedProject);
            return;
        }

        const previousEmployeeRecords = records
            ?.filter(
                (r: WorkHourRecordVm) =>
                    r.employeeId === employeeId && (r.foodSupplierId || r.accommodationSupplierId)
            )
            .sort((a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0));

        this.setState({
            editingRecord:
                record ??
                WorkHourRecordVm.fromJS({
                    projectId: selectedProjectId ? selectedProjectId : undefined,
                    date,
                    employeeId,
                    hours: 8,
                    isFieldWork: true,
                    workHourTypeId: WorkHourTypeEnum.Regular,
                    ...(previousEmployeeRecords?.length
                        ? {
                              foodSupplier: previousEmployeeRecords[0].foodSupplier,
                              foodSupplierId: previousEmployeeRecords[0].foodSupplierId,
                              accommodationSupplier:
                                  previousEmployeeRecords[0].accommodationSupplier,
                              accommodationSupplierId:
                                  previousEmployeeRecords[0].accommodationSupplierId,
                              numberOfMeals: previousEmployeeRecords[0].numberOfMeals,
                          }
                        : {}),
                }),
        });
    };

    private handleCancel = () => {
        this.setState({
            editingRecord: undefined,
        });
    };

    private handleSuccess = (record: WorkHourRecordVm) => {
        const { records } = this.state;

        if (!records) return;

        const index = records?.findIndex((r: WorkHourRecordVm) => r.id === record.id);

        if (index !== -1) {
            this.setState({
                records: [...records.slice(0, index), record, ...records.slice(index! + 1)],
                editingRecord: undefined,
            });
        } else {
            this.setState({
                records: [...records, record],
                editingRecord: undefined,
            });
        }
    };

    private handleDelete = async (id: number) => {
        const { records } = this.state;

        if (!records) return;

        await new WorkHourRecordsClient().delete(id);

        this.setState({
            records: records.filter((r) => r.id !== id),
        });

        this.handleCancel();
    };

    private handleExportByDay = async () => {
        const { selectedProjectId, selectedYear, selectedMonth } = this.state;

        try {
            const result = await new WorkHourRecordsClient().exportByDay(
                ExportWorkHourRecordsByDayXlsxCommand.fromJS({
                    projectId: selectedProjectId ? selectedProjectId : null,
                    year: selectedYear,
                    month: selectedMonth + 1,
                })
            );
            this.handleExportSuccess(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    private handleExportByProject = async () => {
        const { selectedYear, selectedMonth } = this.state;

        try {
            const result = await new WorkHourRecordsClient().exportByProject(
                ExportWorkHourRecordsByProjectsXlsxCommand.fromJS({
                    year: selectedYear,
                    month: selectedMonth + 1,
                })
            );
            this.handleExportSuccess(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    private handleExportSuccess = (result: FileResponse) => {
        showSuccess(translations.documents.exportSuccess);

        downloadFile(result);
    };

    private handleShowSummaryChange = (e: CheckboxChangeEvent) => {
        this.setState({
            showSummary: e.target.checked,
        });
    };

    private handleShowInactiveChange = (e: CheckboxChangeEvent) => {
        this.setState({
            showInactiveEmployees: e.target.checked,
        });
    };

    private getHolidays = async () => {
        const currentYear = new Date().getFullYear();
        this.setState({
            holidays: await new HolidaysClient().getAll(currentYear),
        });
    };

    private handleRecordsCopy = () => {
        this.setState({
            recordCopyDrawerOpen: true,
        });
    };

    private closeRecordsCopy = () => {
        this.setState({ recordCopyDrawerOpen: false });
    };

    private handleRecordsCopied = (newRecords: WorkHourRecordVm[]) => {
        const { records } = this.state;

        if (!records) return;

        this.setState({
            records: [...records, ...newRecords],
            recordCopyDrawerOpen: false,
        });
    };

    private getColumns = (): ColumnProps<TableEmployeeVm>[] => {
        const { records, showSummary, selectedYear, selectedMonth, holidays } = this.state;

        const dates = getDaysInMonthUTC(selectedMonth, selectedYear);

        let columns = dates.map((date: Date): ColumnProps<TableEmployeeVm> => {
            const d = moment(date);
            const isWeekend = d.days() === 6 || d.days() === 0;
            const isToday = d.isSame(new Date(), 'day');
            const isHoliday = holidays?.some((holiday: HolidayVm) =>
                moment(holiday.date).isSame(d, 'day')
            );

            return {
                title: (
                    <span className={isToday ? 'blue-6' : ''}>
                        {d.format(defaultFormat)}
                        <br />
                        {d.format('dddd')}
                    </span>
                ),
                key: d.dayOfYear(),
                width: 95,
                align: 'left',
                className: `data-cell ${isHoliday ? 'ant-bg-gold-2' : ''} ${
                    isWeekend ? 'ant-table-cell-dark' : ''
                } ${isToday ? TODAY_CLASS : ''}`,
                onCell: (employee: TableEmployeeVm) => {
                    return {
                        onClick: () => this.handleEdit(employee.id, date),
                    };
                },
                render: (employee: TableEmployeeVm) => {
                    if (!records) return null;
                    return this.renderCell(employee, date, records);
                },
            };
        });

        columns.unshift({
            title: translations.employees.employees,
            dataIndex: 'fullName',
            width: 130,
            fixed: 'left',
            className: 'ant-table-cell-fixed',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('fullName'),
            sorter: getDefaultComparer('fullName'),
        });

        if (!showSummary) {
            return columns;
        }

        columns.push({
            title: translations.workHourRecods.workHoursTotal,
            width: 75,
            align: 'center',
            fixed: 'right',
            className: 'ant-table-cell-fixed',
            render: (employee: TableEmployeeVm) => {
                const workRecords = records?.filter(
                    (r: WorkHourRecordVm) =>
                        r.employeeId === employee.id &&
                        r.workHourTypeId === WorkHourTypeEnum.Regular
                );

                if (!workRecords?.length) {
                    return '-';
                }

                return formatPrice(sumPropValues(workRecords, 'hours'));
            },
        });
        columns.push({
            title: translations.workHourRecods.fieldWorkDays,
            width: 75,
            align: 'center',
            fixed: 'right',
            className: 'ant-table-cell-fixed',
            render: (employee: TableEmployeeVm) => {
                const fieldWorkRecords = records?.filter(
                    (r: WorkHourRecordVm) => r.employeeId === employee.id && r.isFieldWork
                );

                if (!fieldWorkRecords?.length) {
                    return '-';
                }

                return fieldWorkRecords?.length;
            },
        });
        columns.push({
            title: translations.workHourRecods.vacationHoursTotal,
            width: 75,
            align: 'center',
            fixed: 'right',
            className: 'ant-table-cell-fixed',
            render: (employee: TableEmployeeVm) => {
                const vacationRecords = records?.filter(
                    (r: WorkHourRecordVm) =>
                        r.employeeId === employee.id &&
                        r.workHourTypeId === WorkHourTypeEnum.Vacation
                );

                if (!vacationRecords?.length) {
                    return '-';
                }

                return formatPrice(sumPropValues(vacationRecords, 'hours'));
            },
        });
        columns.push({
            title: translations.workHourRecods.sickLeaveHoursTotal,
            width: 75,
            align: 'center',
            fixed: 'right',
            className: 'ant-table-cell-fixed',
            render: (employee: TableEmployeeVm) => {
                const sickLeaveRecords = records?.filter(
                    (r: WorkHourRecordVm) =>
                        r.employeeId === employee.id &&
                        r.workHourTypeId === WorkHourTypeEnum.SickLeave
                );

                if (!sickLeaveRecords?.length) {
                    return '-';
                }

                return formatPrice(sumPropValues(sickLeaveRecords, 'hours'));
            },
        });
        columns.push({
            title: translations.workHourRecods.sickLeaveInjuryHoursTotal,
            width: 75,
            align: 'center',
            fixed: 'right',
            className: 'ant-table-cell-fixed',
            render: (employee: TableEmployeeVm) => {
                const sickLeaveInjuryRecords = records?.filter(
                    (r: WorkHourRecordVm) =>
                        r.employeeId === employee.id &&
                        r.workHourTypeId === WorkHourTypeEnum.SickLeaveInjury
                );

                if (!sickLeaveInjuryRecords?.length) {
                    return '-';
                }

                return formatPrice(sumPropValues(sickLeaveInjuryRecords, 'hours'));
            },
        });
        columns.push({
            title: translations.workHourRecods.dayOffHoursTotal,
            width: 75,
            align: 'center',
            fixed: 'right',
            className: 'ant-table-cell-fixed',
            render: (employee: TableEmployeeVm) => {
                const dayOffRecords = records?.filter(
                    (r: WorkHourRecordVm) =>
                        r.employeeId === employee.id && r.workHourTypeId === WorkHourTypeEnum.DayOff
                );

                if (!dayOffRecords?.length) {
                    return '-';
                }

                return formatPrice(sumPropValues(dayOffRecords, 'hours'));
            },
        });
        columns.push({
            title: translations.workHourRecods.nonWorkingTotal,
            width: 75,
            align: 'center',
            fixed: 'right',
            className: 'ant-table-cell-fixed',
            render: (employee: TableEmployeeVm) => {
                const nonWorkingRecords = records?.filter(
                    (r: WorkHourRecordVm) =>
                        r.employeeId === employee.id &&
                        r.workHourTypeId === WorkHourTypeEnum.NonWorking
                );

                if (!nonWorkingRecords?.length) {
                    return '-';
                }

                return formatPrice(sumPropValues(nonWorkingRecords, 'hours'));
            },
        });

        return columns;
    };

    private hasGear = (record: WorkHourRecordVm) => {
        return (
            record.shoes ||
            record.shoesPro ||
            record.pants ||
            record.overalls ||
            record.workJacket ||
            record.winterJacket ||
            record.fleeseJacket ||
            record.helmet ||
            record.vest ||
            record.protectionGloves ||
            record.goggles ||
            record.hydrodemolitionSuit
        );
    };

    private getCellType = (record: WorkHourRecordVm): string => {
        switch (record.workHourTypeId) {
            case WorkHourTypeEnum.Regular:
                return '';
            case WorkHourTypeEnum.DayOff:
                return 'SD';
            case WorkHourTypeEnum.NonWorking:
                return 'ND';
            case WorkHourTypeEnum.Vacation:
                return 'GO';
            case WorkHourTypeEnum.SickLeave:
                return 'BOL';
            case WorkHourTypeEnum.SickLeaveInjury:
                return 'ONR';
            default:
                return '';
        }
    };

    private getHoursColor = (record: WorkHourRecordVm): string | undefined => {
        switch (record.workHourTypeId) {
            case WorkHourTypeEnum.Regular:
                return 'blue';
            case WorkHourTypeEnum.DayOff:
                return 'orange';
            case WorkHourTypeEnum.NonWorking:
                return 'blue';
            case WorkHourTypeEnum.Vacation:
                return 'green';
            case WorkHourTypeEnum.SickLeave:
                return 'gold';
            case WorkHourTypeEnum.SickLeaveInjury:
                return 'gold';
            default:
                return '';
        }
    };

    private isEditWorkHourLocked = (month: number, year: number): boolean => {
        const { userProfile, projects, selectedProjectId } = this.state;

        const role = Number(userProfile['userRoleId']);

        if (
            role === RoleEnum.Admin ||
            role === RoleEnum.SuperAdmin ||
            role === RoleEnum.Accounting ||
            role === RoleEnum.Management
        ) {
            return false;
        }

        const now = moment();
        const deadlineInDays = projects.find(
            (x) => x.id === selectedProjectId
        )?.workHourEntryDeadlineExtensionInDays;
        const editDeadline = moment([year, month])
            .endOf('month')
            .add(deadlineInDays !== undefined ? deadlineInDays : initialEntryDeadline, 'days');

        return now > editDeadline;
    };

    private isOwnedRecord = (record?: WorkHourRecordVm): boolean => {
        if (!record) return false;

        const { projects } = this.state;

        return projects.some((project: ProjectVm) => project.id === record.projectId);
    };

    private renderCell = (employee: TableEmployeeVm, date: Date, records?: WorkHourRecordVm[]) => {
        const { selectedView, selectedProjectId, selectedMonth, selectedYear } = this.state;

        const record = records?.find(
            (r: WorkHourRecordVm) =>
                r.employeeId === employee.id && moment(date).isSame(moment(r.date), 'day')
        );

        if (!record) return null;

        const isDisabled =
            !this.isOwnedRecord(record) || this.isEditWorkHourLocked(selectedMonth, selectedYear);

        const isDiffProject = !!selectedProjectId && record.projectId !== selectedProjectId;

        const hoursColor = isDisabled
            ? 'default'
            : isDiffProject
            ? 'red'
            : this.getHoursColor(record);
        const accommodationColor = isDisabled ? 'default' : isDiffProject ? 'red' : 'blue';
        const foodColor = isDisabled ? 'default' : isDiffProject ? 'red' : 'blue';

        if (selectedView === ViewTypeEnum.Hours) {
            return (
                <Tooltip title={record.project}>
                    <Tag color={hoursColor}>
                        <Space size={'small'}>
                            {record.hours ? formatPrice(record.hours) : '0,00'}
                            {this.getCellType(record)}
                            {record.isFieldWork && 'T'}
                            {record.workRecordCoefficient &&
                                formatPrice(record.workRecordCoefficient)}
                        </Space>
                    </Tag>
                </Tooltip>
            );
        }

        if (selectedView === ViewTypeEnum.Accommodation) {
            return record.accommodationSupplier ? (
                <Tooltip title={record.accommodationSupplier}>
                    <Tag color={accommodationColor}>
                        {truncateString(record.accommodationSupplier, 15)}
                    </Tag>
                </Tooltip>
            ) : (
                <Tag color={accommodationColor}>{'-'}</Tag>
            );
        }

        if (selectedView === ViewTypeEnum.Food) {
            return (
                <Tooltip title={record.foodSupplier}>
                    <Tag color={foodColor}>
                        {record.foodSupplier ? truncateString(record.foodSupplier, 15) : '-'}
                    </Tag>
                </Tooltip>
            );
        }

        if (selectedView === ViewTypeEnum.Gear) {
            return <Tag color={foodColor}>{this.hasGear(record) ? <CheckOutlined /> : '-'}</Tag>;
        }
    };

    render() {
        const { project } = this.props;
        const {
            employees,
            projects,
            selectedProjectId,
            selectedYear,
            selectedMonth,
            selectedView,
            showSummary,
            showInactiveEmployees,
            editingRecord,
            loading,
            recordCopyDrawerOpen,
        } = this.state;
        const currentProject = projects.find((p) => p.id === selectedProjectId);

        return (
            <>
                <PageHeader
                    title={
                        <Space>
                            {!project && !!projects.length && (
                                <Select
                                    value={selectedProjectId}
                                    onChange={(value: number) => this.handleProjectChange(value)}
                                    style={{ width: 300 }}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    <Select.Option value={0}>
                                        {translations.projects.all}
                                    </Select.Option>
                                    {projects.map((p: ProjectVm) => (
                                        <Select.Option key={p.id} value={p.id}>
                                            {p.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                            <Radio.Group
                                value={selectedView}
                                onChange={(e: RadioChangeEvent) => {
                                    this.setState({ selectedView: e.target.value });
                                }}
                            >
                                <Radio value={ViewTypeEnum.Hours}>Evidencija sati</Radio>
                                <Radio value={ViewTypeEnum.Accommodation}>Smještaj</Radio>
                                <Radio value={ViewTypeEnum.Food}>Prehrana</Radio>
                                <Radio value={ViewTypeEnum.Gear}>HTZ oprema</Radio>
                            </Radio.Group>
                            {
                                <Button
                                    disabled={selectedProjectId === 0}
                                    type="primary"
                                    onClick={this.handleRecordsCopy}
                                    className="no-print"
                                >
                                    <CopyOutlined />
                                    {translations.workHourRecods.copyRecords}
                                </Button>
                            }
                        </Space>
                    }
                    extra={
                        <Space style={{ float: 'right' }}>
                            <Checkbox
                                disabled={selectedProjectId === 0}
                                value={showInactiveEmployees}
                                onChange={this.handleShowInactiveChange}
                                style={{ lineHeight: '32px' }}
                            >
                                {translations.workHourRecods.showInactive}
                            </Checkbox>
                            <Checkbox
                                value={showSummary}
                                onChange={this.handleShowSummaryChange}
                                style={{ lineHeight: '32px' }}
                            >
                                {translations.workHourRecods.showSummary}
                            </Checkbox>
                            <Button onClick={this.goToToday}>
                                {translations.workHourRecods.today}
                            </Button>
                            <Select
                                value={selectedYear}
                                onChange={(value: number) =>
                                    this.setState({ selectedYear: value }, () => {
                                        this.getRecords();
                                        this.resetTableScroll();
                                    })
                                }
                                style={{ width: 100 }}
                                showSearch
                                optionFilterProp="children"
                            >
                                {getYears(MIN_YEAR, moment().year() + 1).map((year) => (
                                    <Select.Option key={year} value={year}>
                                        {year}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                value={selectedMonth}
                                onChange={(value: number) =>
                                    this.setState({ selectedMonth: value }, () => {
                                        this.getRecords();
                                        this.resetTableScroll();
                                    })
                                }
                                style={{ width: 100 }}
                                showSearch
                                optionFilterProp="children"
                            >
                                {moment.months().map((month: string, index: number) => (
                                    <Select.Option key={index} value={index}>
                                        {month}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Dropdown
                                className="no-print"
                                trigger={['click']}
                                menu={{
                                    items: [
                                        {
                                            key: 'exportXlsxByDay',
                                            onClick: (e) => {
                                                e.domEvent.stopPropagation();
                                                this.handleExportByDay();
                                            },
                                            icon: <FileExcelOutlined />,
                                            label: translations.general.exportXlsxByDay,
                                        },
                                        {
                                            key: 'exportXlsxByProject',
                                            onClick: (e) => {
                                                e.domEvent.stopPropagation();
                                                this.handleExportByProject();
                                            },
                                            icon: <FileExcelOutlined />,
                                            label: translations.general.exportXlsxByProject,
                                        },
                                    ],
                                }}
                            >
                                <Button
                                    key="export"
                                    style={{ zIndex: 10 }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <DownOutlined />
                                    {translations.general.export}
                                </Button>
                            </Dropdown>
                        </Space>
                    }
                />

                <Table
                    locale={getTableLocale()}
                    columns={this.getColumns()}
                    dataSource={
                        selectedProjectId === 0 || showInactiveEmployees
                            ? employees
                            : employees?.filter(
                                  (em) => !em.activeTo || em.activeTo > moment().toDate()
                              )
                    }
                    loading={!!loading}
                    rowKey={(employee: TableEmployeeVm): number => employee.id}
                    bordered
                    pagination={defaultTablePagination}
                    className="ant-table-slim"
                    rowClassName="no-hover"
                    scroll={{ x: 'max-content', y: `calc(100vh - ${NON_TABLE_CONTENT_HEIGHT}px)` }}
                    size="small"
                />

                {editingRecord && (
                    <Drawer
                        title={translations.workHourRecods.form.title}
                        open={!!editingRecord}
                        onClose={this.handleCancel}
                        width={DRAWER_WIDTH}
                        destroyOnClose
                    >
                        {employees && (
                            <WorkHourRecordForm
                                record={editingRecord}
                                projects={projects}
                                employees={employees}
                                onClose={this.handleCancel}
                                onSuccess={this.handleSuccess}
                                onDelete={() =>
                                    showConfirm(
                                        () => this.handleDelete(editingRecord.id),
                                        translations.workHourRecods.deleteConfirmMessege,
                                        translations.general.delete,
                                        true
                                    )
                                }
                            />
                        )}
                    </Drawer>
                )}

                {recordCopyDrawerOpen && (
                    <Drawer
                        title={translations.workHourRecods.copyRecords}
                        open={!!recordCopyDrawerOpen}
                        onClose={this.closeRecordsCopy}
                    >
                        <WorkHourRecordsCopyForm
                            projectName={currentProject?.name}
                            hasEditDeadlineExpired={this.isEditWorkHourLocked}
                            onClose={this.closeRecordsCopy}
                            onSuccess={this.handleRecordsCopied}
                            projectId={this.state.selectedProjectId}
                        />
                    </Drawer>
                )}
            </>
        );
    }
}

export default WorkHourRecordsTable;
