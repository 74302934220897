import React from 'react';
import { Props } from './index';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import {
    Button,
    DatePicker,
    Drawer,
    Dropdown,
    Form,
    FormInstance,
    InputNumber,
    message,
    Modal,
    PageHeader,
    Select,
    Space,
} from 'antd';
import { translations } from '../../config/translations';
import {
    CopyOutlined,
    DownOutlined,
    FileExcelOutlined,
    LockOutlined,
    SettingOutlined,
    UnlockOutlined,
} from '@ant-design/icons';
import SelectMonth from '../../components/select-month';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import { ActionType, ModuleName } from '../../core/models/enum';
import {
    CompaniesClient,
    CompanyVm,
    ConfigurableConstantsClient,
    ConfigurableConstantsVm,
    CreatePayrollCommand,
    PayrollsClient,
    PayrollVm,
    ToggleLockPayrollCommand,
} from '../../utils/api';
import { SelectOption } from '../../core/models/SelectOption';
import PayrollTable from './payrolls-table/PayrollsTable';
import { getFiltersParams, getPagingSortingParams } from '../../helpers/SearchParamsHelper';
import { capitalizeFirstLetter } from '../../helpers/StringHelper';
import { defaultTablePagination } from '../../config/constants';
import { downloadFile } from '../../helpers/FileHelper';
import { showConfirm } from '../../helpers/NotificationHelper';

interface State {
    allowCreate: boolean;
    companies: SelectOption[];
    payroll?: PayrollVm;
    loading: boolean;
    createLoading: boolean;
    lockLoading: boolean;
    isExporting: boolean;
    totalItems: number;
    selectedCompany?: number;
    selectedMonth: number;
    selectedYear: number;
    drawerVisible: boolean;
    configurableConstants?: ConfigurableConstantsVm;
    labelMapping: { [key: string]: string };
}

class Payrolls extends React.Component<Props & RouteComponentProps, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props & RouteComponentProps) {
        super(props);

        this.state = {
            allowCreate: authorizeAction(
                props.userProfile,
                ModuleName.Cooperators,
                ActionType.Create
            ),
            companies: [],
            loading: false,
            createLoading: false,
            lockLoading: false,
            isExporting: false,
            totalItems: 0,
            selectedCompany: this.getSelectedCompanyFromUrl() || 1, // Default to company ID 1
            selectedMonth: this.getSelectedMonthFromUrl(),
            selectedYear: this.getSelectedYearFromUrl(),
            drawerVisible: false,
            labelMapping: {
                hourlyRateDividerBOL: translations.payrolls.hourlyRateDividerBOL,
                hourlyRateDividerBOLWorkInjury:
                    translations.payrolls.hourlyRateDividerBOLWorkInjury,
                hourlyRateDividerGO: translations.payrolls.hourlyRateDividerGO,
                amountOfFieldAllowance: translations.payrolls.amountOfFieldAllowance,
                maxAmountOfAnnualBonus: translations.payrolls.maxAmountOfAnnualBonus,
                maxAmountOfFieldAllowance: translations.payrolls.maxAmountOfFieldAllowance,
            },
        };
    }

    public componentDidMount = () => {
        this.initializeDataFromUrl();
        this.getCompanies();
    };

    public componentDidUpdate(prevProps: Props & RouteComponentProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.initializeDataFromUrl();
        }
    }

    private formRef = React.createRef<FormInstance>();

    private initializeDataFromUrl = () => {
        const selectedCompany = this.getSelectedCompanyFromUrl();
        const selectedMonth = this.getSelectedMonthFromUrl();
        const selectedYear = this.getSelectedYearFromUrl();
        debugger;
        this.setState(
            { selectedCompany: selectedCompany ? selectedCompany : 1, selectedMonth, selectedYear },
            () => {
                this.getEmployeePayrolls();
            }
        );
    };

    private handleCreatePayroll = async () => {
        const { payroll } = this.state;
        this.setState({ createLoading: true });
        try {
            if (!(payroll?.id === undefined || payroll.id === 0)) {
                showConfirm(
                    this.createPayroll,
                    'Ponovno generiranje obračuna će izbrisati postojeći obračun. Jeste li sigurni da želite nastaviti?',
                    translations.payrolls.regenerate,
                    true
                );
            } else {
                await this.createPayroll();
            }
        } catch (error) {
            message.error('Došlo je do greške prilikom provjere obračuna.');
        }
        this.setState({ createLoading: false });
    };

    private createPayroll = async () => {
        const { selectedCompany, selectedMonth, selectedYear } = this.state;

        const request = new CreatePayrollCommand({
            companyId: selectedCompany || 1,
            month: selectedMonth,
            year: selectedYear,
        });

        try {
            await new PayrollsClient().createCalculations(request);
            message.success('Obračun uspješno generiran');
        } catch (error) {
            message.error('Došlo je do greške prilikom stvaranja obračuna.');
        }
        await this.getEmployeePayrolls();
    };

    private getEmployeePayrolls = async () => {
        const { selectedCompany, selectedMonth, selectedYear } = this.state;
        this.setState({ loading: true });

        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        try {
            const result = await new PayrollsClient().getPaginated(
                filters.Employee_Name?.toString(),
                selectedCompany ? selectedCompany : 1,
                pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
                sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
                sortOrderParam,
                selectedMonth,
                selectedYear
            );

            this.setState({
                payroll: result.pageItems,
                totalItems: result.totalItems,
                loading: false,
            });
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    private updateQueryParams = (companyId?: number, month?: number, year?: number) => {
        const params = new URLSearchParams(this.props.location.search);
        if (companyId) {
            params.set('companyId', companyId.toString());
        }
        if (month) {
            params.set('month', month.toString());
        }
        if (year) {
            params.set('year', year.toString());
        }
        this.props.history.push({ search: params.toString() });
    };

    private getCompanies = async () => {
        const companies = (await new CompaniesClient().getAll()).map(
            (unit: CompanyVm): SelectOption => ({
                label: unit.name,
                value: unit.id,
            })
        );

        if (companies.length > 0) {
            const { selectedCompany, selectedMonth, selectedYear } = this.state;
            const companyToSelect = selectedCompany || companies[0].value;
            this.setState({ companies, selectedCompany: companyToSelect }, () => {
                this.updateQueryParams(companyToSelect, selectedMonth, selectedYear);
            });
        }
    };

    private getConfigurableConstants = async () => {
        const configurableConstants =
            await new ConfigurableConstantsClient().getConfigurableConstants();
        this.setState({ configurableConstants });
    };

    private handleDrawerOpen = async () => {
        this.setState({ drawerVisible: true }, await this.getConfigurableConstants);
    };

    private handleDrawerClose = () => {
        this.setState({ drawerVisible: false });
    };

    private handleSaveConstants = async () => {
        const { configurableConstants } = this.state;
        if (configurableConstants) {
            try {
                await new ConfigurableConstantsClient().updateConfigurableConstants(
                    configurableConstants.id,
                    configurableConstants
                );
                message.success('Konfigurabilne konstanse su uspješno ažurirane.');
                this.handleDrawerClose();
            } catch (error) {
                message.error('Došlo je do greške prilikom ažuriranja konfigurabilnih konstanti.');
            }
        }
    };

    private handleChange = (key: string, value: number) => {
        this.setState((prevState) => ({
            configurableConstants: {
                ...prevState.configurableConstants,
                [key]: value,
            } as ConfigurableConstantsVm,
        }));
    };

    private handleSelectCompany = (value: number | undefined) => {
        this.setState({ selectedCompany: value ? value : 1 }, () => {
            this.props.history.push({
                search: new URLSearchParams({
                    companyId: value?.toString() ?? '1',
                    month: this.state.selectedMonth.toString(),
                    year: this.state.selectedYear.toString(),
                    pageIndex: '1', // Reset page index to 1 when changing company
                }).toString(),
            });
        });
    };

    private handleSelectMonth = (value: number) => {
        this.setState({ selectedMonth: value }, () => {
            this.updateQueryParams(this.state.selectedCompany, value, this.state.selectedYear);
        });
    };

    private handleYearChange = (date: moment.Moment | null) => {
        const year = date ? date.year() : this.state.selectedYear;
        this.setState({ selectedYear: year }, () => {
            this.updateQueryParams(this.state.selectedCompany, this.state.selectedMonth, year);
        });
    };

    private getSelectedCompanyFromUrl = (): number | undefined => {
        const params = new URLSearchParams(this.props.location.search);
        return params.get('companyId') ? parseInt(params.get('companyId')!) : 1;
    };

    private handlePayrollExport = async () => {
        const { payroll } = this.state;
        if (payroll?.id === undefined) return;
        this.setState({ isExporting: true });
        try {
            let result = await new PayrollsClient().generatePayrollXlsx(payroll?.id);
            downloadFile(result);
        } catch (error) {
            message.error('Došlo je do greške prilikom izvoza obračuna.');
        }
        this.setState({ isExporting: false });
    };

    private handlePayrollHoursExport = async () => {
        const { payroll } = this.state;
        if (payroll?.id === undefined) return;
        this.setState({ isExporting: true });
        try {
            let result = await new PayrollsClient().generatePayrollFormalWorkHourRecordsXlsx(
                payroll?.id
            );
            downloadFile(result);
        } catch (error) {
            message.error('Došlo je do greške prilikom izvoza obračuna.');
        }
        this.setState({ isExporting: false });
    };

    private handleLockPayroll = async () => {
        const { payroll } = this.state;
        if (!payroll) {
            return;
        }
        const message = payroll.isApproved
            ? translations.payrolls.unlockMessage
            : translations.payrolls.lockMessage;

        this.setState({ lockLoading: true });

        Modal.confirm({
            title: message,
            okText: payroll.isApproved ? translations.payrolls.unlock : translations.payrolls.lock,
            cancelText: translations.general.cancel,
            onOk: async () => {
                await new PayrollsClient().toggleLockPayroll(
                    payroll.id,
                    new ToggleLockPayrollCommand({
                        payrollId: payroll.id,
                        isLocked: payroll.isApproved,
                    })
                );
                await this.getEmployeePayrolls();
                this.setState({ lockLoading: false });
            },
            onCancel: () => {
                this.setState({ lockLoading: false });
            },
        });
    };

    private getSelectedMonthFromUrl = (): number => {
        const params = new URLSearchParams(this.props.location.search);
        return params.get('month')
            ? parseInt(params.get('month')!)
            : (moment().month() as number) + 1;
    };

    private getSelectedYearFromUrl = (): number => {
        const params = new URLSearchParams(this.props.location.search);
        return params.get('year') ? parseInt(params.get('year')!) : moment().year();
    };

    labelMapping: { [key: string]: string } = {
        hourlyRateDividerBOL: 'Djeljitelj satnice BOL',
        hourlyRateDividerBOLWorkInjury: 'Djeljitelj satnice BOL (ozljeda na radu)',
        hourlyRateDividerGO: 'Djeljitelj satnice GO',
        amountOfFieldAllowance: 'Iznos terenskog dodatka',
        maxAmountOfAnnualBonus: 'Max. iznos godišnje nagrade',
        maxAmountOfFieldAllowance: 'Max. iznos terenskog dodatka',
    };

    public render(): React.ReactElement {
        const {
            allowCreate,
            companies,
            selectedCompany,
            selectedMonth,
            payroll,
            loading,
            createLoading,
            isExporting,
            lockLoading,
            selectedYear,
            drawerVisible,
            configurableConstants,
            labelMapping,
            totalItems,
        } = this.state;

        return (
            <>
                <PageHeader
                    title={translations.payrolls.payrolls}
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      className="no-print"
                                      onClick={this.handleCreatePayroll}
                                      loading={createLoading}
                                      disabled={payroll?.isApproved}
                                  >
                                      <CopyOutlined />
                                      {payroll?.id === undefined || payroll.id === 0
                                          ? translations.payrolls.generate
                                          : translations.payrolls.regenerate}
                                  </Button>,
                                  <Button
                                      key="2"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      className="print"
                                      onClick={this.handleLockPayroll}
                                      loading={lockLoading}
                                      disabled={payroll?.id === undefined || payroll.id === 0}
                                  >
                                      {payroll?.isApproved ? (
                                          <>
                                              <UnlockOutlined style={{ marginRight: '0.5rem' }} />
                                              {translations.payrolls.unlock}
                                          </>
                                      ) : (
                                          <>
                                              <LockOutlined style={{ marginRight: '0.5rem' }} />
                                              {translations.payrolls.lock}
                                          </>
                                      )}
                                  </Button>,
                                  <Button key="3" type="primary" onClick={this.handleDrawerOpen}>
                                      <SettingOutlined />
                                  </Button>,
                                  <Dropdown
                                      key="4"
                                      className="no-print"
                                      trigger={['click']}
                                      disabled={payroll?.id === undefined || payroll.id === 0}
                                      menu={{
                                          items: [
                                              {
                                                  key: 'exportXlsxPayroll',
                                                  onClick: (e) => {
                                                      e.domEvent.stopPropagation();
                                                      this.handlePayrollExport();
                                                  },
                                                  icon: <FileExcelOutlined />,
                                                  label: translations.payrolls.exportPayroll,
                                              },
                                              {
                                                  key: 'exportXlsxHours',
                                                  onClick: (e) => {
                                                      e.domEvent.stopPropagation();
                                                      this.handlePayrollHoursExport();
                                                  },
                                                  icon: <FileExcelOutlined />,
                                                  disabled: isExporting,
                                                  label: translations.payrolls.exportPayrollHours,
                                              },
                                          ],
                                      }}
                                  >
                                      <Button
                                          key="export"
                                          style={{ zIndex: 10 }}
                                          onClick={(e) => e.stopPropagation()}
                                          loading={isExporting}
                                      >
                                          <DownOutlined />
                                          {translations.general.export}
                                      </Button>
                                  </Dropdown>,
                              ]
                            : []
                    }
                >
                    <Space direction="horizontal" size="large">
                        <div>
                            <span style={{ width: '30%', marginRight: '10px' }}>
                                {translations.payrolls.company}:
                            </span>
                            <Select<number>
                                showSearch
                                placeholder={translations.employees.companyPlaceholder}
                                options={companies}
                                onSelect={this.handleSelectCompany}
                                value={selectedCompany}
                                style={{ width: '70%', minWidth: '200px' }}
                            />
                        </div>
                        <div>
                            <span style={{ width: '30%', marginRight: '10px' }}>
                                {translations.payrolls.month}:
                            </span>
                            <SelectMonth
                                selectedMonth={selectedMonth}
                                onMonthSelected={this.handleSelectMonth}
                            />
                        </div>
                        <div>
                            <span style={{ width: '30%', marginRight: '10px' }}>
                                {translations.payrolls.year}:
                            </span>
                            <DatePicker
                                picker="year"
                                value={moment(selectedYear, 'YYYY')}
                                onChange={(date) => this.handleYearChange(date)}
                                style={{ width: '70%' }}
                                allowClear={false}
                            />
                        </div>
                    </Space>
                </PageHeader>
                <PayrollTable
                    wrappedComponentRef={this.tableRef}
                    selectedCompanyId={selectedCompany}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    employeePayrolls={payroll?.employeePayrolls ? payroll.employeePayrolls : []}
                    loading={loading}
                    fetchEmployeeSalaries={this.getEmployeePayrolls}
                    totalCount={totalItems}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                />
                <Drawer
                    title="Konfigurabilne konstante"
                    placement="right"
                    onClose={this.handleDrawerClose}
                    open={drawerVisible}
                    width={500}
                >
                    {configurableConstants ? (
                        <Form
                            layout="vertical"
                            ref={this.formRef}
                            initialValues={configurableConstants}
                            onFinish={this.handleSaveConstants}
                            onValuesChange={(changedValues) => {
                                const key = Object.keys(changedValues)[0];
                                const value = changedValues[key];
                                this.handleChange(key, value);
                            }}
                        >
                            {Object.keys(configurableConstants)
                                .filter((key) => key !== 'id') // Exclude the 'id' field
                                .map((key, index) => {
                                    const isRequired = index < 3 || index === 5; // The first 3 fields are required
                                    return (
                                        <Form.Item
                                            key={key}
                                            label={labelMapping[key]}
                                            name={key}
                                            rules={[
                                                {
                                                    required: isRequired,
                                                    message: `Polje ${labelMapping[key]} je obavezno.`,
                                                },
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            isRequired &&
                                                            (value === undefined || value <= 0)
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    `Polje ${labelMapping[key]} mora biti veće od 0.`
                                                                )
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                precision={2}
                                                decimalSeparator=","
                                            />
                                        </Form.Item>
                                    );
                                })}
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={this.handleDrawerClose} style={{ marginRight: 8 }}>
                                    {translations.general.cancel}
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {translations.general.save}
                                </Button>
                            </div>
                        </Form>
                    ) : (
                        <p>Loading...</p>
                    )}
                </Drawer>
            </>
        );
    }
}

export default Payrolls;
