import { Layout as AntLayout, Button } from 'antd';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Routes from '../config/routes';
import { RouteParams } from '../core/models/RouteParams';
import { RoleEnum } from '../utils/api';
import SideMenu from './SideMenu';
import authService from './api-authorization/AuthorizeService';
import { LoginMenu } from './api-authorization/LoginMenu';
import { LeftOutlined } from '@ant-design/icons';

const { Content, Sider } = AntLayout;

type Props = RouteComponentProps<RouteParams>;

interface State {
    collapsed: boolean;
    userRole?: RoleEnum;
}
class Layout extends React.Component<Props, State> {
    _subscription: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            collapsed: false,
            userRole: undefined,
        };
    }

    componentDidMount = async () => {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    };

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateAuthenticationState() {
        this.setState({ userRole: await authService.getUserRole() });
    }

    async authenticationChanged() {
        this.setState({ userRole: undefined });
        await this.populateAuthenticationState();
    }

    onCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const { collapsed, userRole } = this.state;
        const { history, location } = this.props;

        return (
            <AntLayout style={{ minHeight: '100vh' }}>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}
                    className="no-print"
                >
                    <div className="logo">
                        <Link to={Routes.ROUTE_DASHBOARD}>
                            <img src="/images/logo_v3.svg" alt="icon-logo"></img>
                            <img src="/images/logo-name.svg" alt="icon-logo"></img>
                        </Link>
                    </div>
                    {userRole && <SideMenu />}
                    <LoginMenu />
                </Sider>

                <AntLayout>
                    <Button
                        type="primary"
                        shape="circle"
                        className="no-print"
                        style={{ marginTop: 15, marginLeft: 15, zIndex: 1000 }}
                        icon={<LeftOutlined />}
                        size={'small'}
                        disabled={location.key ? false : true}
                        onClick={() => history.go(-1)}
                    />
                    <Content>{this.props.children}</Content>
                </AntLayout>
            </AntLayout>
        );
    }
}

export default withRouter(Layout);
