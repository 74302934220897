import Table, { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { getSearchFilter } from '../../../helpers/FilterHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import { EmployeePayrollVm, IPayrollItemVm, PayrollsClient } from '../../../utils/api';
import {
    getPagingSortingParams,
    applySearchParams,
    getFiltersParams,
} from '../../../helpers/SearchParamsHelper';
import { isVisible } from '../../../helpers/ProjectItemHelper';
import scrollIntoView from 'scroll-into-view';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, FormInstance, message, Space, Tabs } from 'antd';
import { Props } from '.';
import TabPane from 'antd/lib/tabs/TabPane';
import { formatPrice } from '../../../helpers/PriceHelper';

const EDITING_ROW_CLASS = 'editing-row';
const SCROLLABLE_CONTAINER_CLASS = 'ant-table-body';

const PayrollTable: React.FC<Props> = (props: Props) => {
    const [editingItem, setEditingItem] = useState<EmployeePayrollVm | undefined>(undefined);
    const formRef = useRef<FormInstance>(null);
    const history = useHistory();
    const location = useLocation();
    const [activeTabKey, setActiveTabKey] = useState('1');

    useEffect(() => {
        const editingRow = document.querySelector<HTMLElement>(`.${EDITING_ROW_CLASS}`);
        const container = document.querySelector<HTMLElement>(`.${SCROLLABLE_CONTAINER_CLASS}`);

        if (editingItem && editingRow && container && !isVisible(editingRow, container)) {
            scrollIntoView(editingRow, {
                align: {
                    top: 1,
                },
            });
        }
    }, [editingItem]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<EmployeePayrollVm> | SorterResult<EmployeePayrollVm>[] = {}
    ) => {
        const currSearchParams = new URLSearchParams(location.search);
        const tableSearchParams = createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    const createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<EmployeePayrollVm> | SorterResult<EmployeePayrollVm>[] = {}
    ): URLSearchParams => {
        const tableSearchParams = new URLSearchParams();

        // Handle pagination
        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );

        // Handle sorting
        const singleSorter = sorter as SorterResult<EmployeePayrollVm>;
        let sortBy;
        if (
            Array.isArray(singleSorter.field) &&
            singleSorter.field[0] === 'employee' &&
            singleSorter.field[1] === 'fullName'
        ) {
            sortBy = 'Employee_Name';
        } else {
            sortBy = singleSorter.field?.toString() || undefined;
        }

        if (sortBy && singleSorter.order) {
            tableSearchParams.set('sortBy', sortBy);
            tableSearchParams.set('sortOrder', singleSorter.order);
        } else {
            tableSearchParams.delete('sortBy');
            tableSearchParams.delete('sortOrder');
        }

        // Handle filtering
        const employeeFullNameFilter = filters['employee.fullName'];
        if (employeeFullNameFilter) {
            const filterValue = employeeFullNameFilter.toString();
            tableSearchParams.set('filter.Employee_Name', filterValue || '');
        }

        // Append the selected company, month, and year to the URL
        tableSearchParams.set('companyId', props.selectedCompanyId?.toString() || '');
        tableSearchParams.set('month', props.selectedMonth?.toString() || '');
        tableSearchParams.set('year', props.selectedYear?.toString() || '');

        return tableSearchParams;
    };

    const handleEdit = (item: Partial<EmployeePayrollVm>) => {
        setEditingItem(item as EmployeePayrollVm);
    };

    const handleCancelEdit = useCallback(() => {
        setEditingItem(undefined);
    }, [setEditingItem]);

    const handleSaveEdit = useCallback(() => {
        if (formRef.current) {
            formRef.current.submit();
        }
    }, [formRef]);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                handleCancelEdit();
            } else if (e.key === 'Enter') {
                handleSaveEdit();
            }
        },
        [handleCancelEdit, handleSaveEdit]
    );

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleFormSubmit = async (values: any) => {
        if (!editingItem) return;

        try {
            if (editingItem && !editingItem.employee) return;

            const newPayrollItem = {
                ...values,
                id: editingItem.payrollItem?.id || 0,
            };

            const existingEmployeePayroll = props.employeePayrolls.find(
                (employeePayroll) => employeePayroll.employee?.id === editingItem.employee?.id
            );
            if (existingEmployeePayroll && existingEmployeePayroll.payrollItem?.id) {
                await new PayrollsClient().update(
                    existingEmployeePayroll.payrollItem.id,
                    newPayrollItem
                );
                message.success(translations.payrolls.successUpdate);
            }

            setEditingItem(undefined);
            props.fetchEmployeeSalaries();
        } catch (error) {
            console.error(error);
            message.error(translations.payrolls.failedMessage);
        }
    };

    const formatNumber = (
        num: number | string | undefined,
        payrollItem: IPayrollItemVm | undefined
    ): string => {
        if (payrollItem) {
            return num ? formatPrice(num) : '0,00';
        }
        return '';
    };

    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const getTableColumns = (): ColumnProps<EmployeePayrollVm>[] => {
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        return [
            {
                title: translations.payrolls.employee,
                dataIndex: ['employee', 'fullName'],
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.Employee_Name ? [filters.Employee_Name] : null,
                sortOrder: sortByParam === 'Employee_Name' ? sortOrderParam : null,
                width: 250,
                fixed: true,
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_EMPLOYEES_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.employee?.id?.toString() || '0'
                        )}
                    >
                        {text}
                    </Link>
                ),
            },
            {
                title: translations.payrolls.hourlyRateBOL,
                dataIndex: ['payrollItem', 'sickLeaveHourlyRate'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.hourlyRateONR,
                dataIndex: ['payrollItem', 'otherAbsenceHourlyRate'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.hourlyRateGO,
                dataIndex: ['payrollItem', 'vacationHourlyRate'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.regularWorkHours,
                dataIndex: ['payrollItem', 'regularWorkHours'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.hoursBOL,
                dataIndex: ['payrollItem', 'sickLeaveHours'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.hoursONR,
                dataIndex: ['payrollItem', 'otherAbsenceHours'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.hoursGO,
                dataIndex: ['payrollItem', 'vacationHours'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.fieldDays,
                dataIndex: ['payrollItem', 'fieldDays'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.totalRegularWork,
                dataIndex: ['payrollItem', 'totalRegularWork'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.totalBOL,
                dataIndex: ['payrollItem', 'totalSickLeave'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.totalONR,
                dataIndex: ['payrollItem', 'totalOtherAbsence'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.totalGO,
                dataIndex: ['payrollItem', 'totalVacation'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.totalFieldWork,
                dataIndex: ['payrollItem', 'totalFieldWork'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.additions,
                dataIndex: ['payrollItem', 'allowances'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: <b>{translations.payrolls.total}</b>,
                dataIndex: ['payrollItem', 'total'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <b>{formatNumber(text, record.payrollItem)}</b>
                ),
            },
        ];
    };

    const getAdministartionTableColumns = (): ColumnProps<EmployeePayrollVm>[] => {
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        return [
            {
                title: translations.payrolls.employee,
                dataIndex: ['employee', 'fullName'],
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.Employee_Name ? [filters.Employee_Name] : null,
                sortOrder: sortByParam === 'Employee_Name' ? sortOrderParam : null,
                width: 250,
                fixed: true,
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_EMPLOYEES_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.employee?.id?.toString() || '0'
                        )}
                    >
                        {text}
                    </Link>
                ),
            },
            {
                title: translations.payrolls.workingDaysInMonth,
                dataIndex: ['payrollItem', 'numberOfWorkingDaysInMonth'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.fieldWorkFundDays,
                dataIndex: ['payrollItem', 'fieldWorkFundDaysTotal'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement => (
                    <span>{formatNumber(text, record.payrollItem)}</span>
                ),
            },
            {
                title: translations.payrolls.fieldWorkFundDaysEmployee,
                dataIndex: ['payrollItem', 'fieldWorkFundDays'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="fieldWorkFundDays"
                            initialValue={editingItem?.payrollItem?.fieldWorkFundDays}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.payrolls.fieldWorkFundDays}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.payrollItem)}</span>
                    ),
            },
            {
                title: translations.payrolls.fieldWorkDaysForPayment,
                dataIndex: ['payrollItem', 'numberOfFieldDaysForPayment'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="numberOfFieldDaysForPayment"
                            initialValue={editingItem?.payrollItem?.numberOfFieldDaysForPayment}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.payrolls.fieldWorkDaysForPayment}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.payrollItem)}</span>
                    ),
            },
            {
                title: <b>{translations.payrolls.netAmount1}</b>,
                dataIndex: ['payrollItem', 'netAmount'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="netAmount"
                            initialValue={editingItem?.payrollItem?.netAmount}
                            rules={[
                                { required: true, message: translations.general.requiredField },
                            ]}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.payrolls.netAmount1}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <b>{formatNumber(text, record.payrollItem)}</b>
                    ),
            },
            {
                title: <b>{translations.payrolls.fieldWorkAddition2}</b>,
                dataIndex: ['payrollItem', 'fieldAllowance'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="fieldAllowance"
                            initialValue={editingItem?.payrollItem?.fieldAllowance}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.payrolls.fieldWorkAddition2}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <b>{formatNumber(text, record.payrollItem)}</b>
                    ),
            },
            {
                title: <b>{translations.payrolls.reward3}</b>,
                dataIndex: ['payrollItem', 'bonus'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="bonus"
                            initialValue={editingItem?.payrollItem?.bonus}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.payrolls.reward3}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <b>{formatNumber(text, record.payrollItem)}</b>
                    ),
            },
            {
                title: <b>{translations.payrolls.stimulations4}</b>,
                dataIndex: ['payrollItem', 'incentives'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="incentives"
                            initialValue={editingItem?.payrollItem?.incentives}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.payrolls.stimulations4}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <b>{formatNumber(text, record.payrollItem)}</b>
                    ),
            },
            {
                title: <b>{translations.payrolls.accountingTotal}</b>,
                dataIndex: ['payrollItem', 'accountingTotal'],
                align: 'right',
                render: (text: string, record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="accountingTotal"
                            initialValue={editingItem?.payrollItem?.accountingTotal}
                            rules={[
                                { required: true, message: translations.general.requiredField },
                            ]}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.payrolls.accountingTotal}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <b>{formatNumber(text, record.payrollItem)}</b>
                    ),
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'center',
                render: (record: EmployeePayrollVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Space>
                            <Button
                                shape="circle"
                                type="primary"
                                size="small"
                                onClick={handleSaveEdit}
                                icon={<CheckOutlined />}
                            />
                            <Button
                                shape="circle"
                                size="small"
                                onClick={handleCancelEdit}
                                icon={<CloseOutlined />}
                            />
                        </Space>
                    ) : (
                        <>
                            {record.payrollItem && Object.keys(record.payrollItem).length > 0 && (
                                <Button
                                    type="link"
                                    onClick={() => handleEdit(record)}
                                    style={{ padding: 0, marginRight: 8 }}
                                >
                                    <EditOutlined />
                                </Button>
                            )}
                        </>
                    ),
            },
        ];
    };

    const params = new URLSearchParams(location.search);
    const { pageIndexParam, pageSizeParam } = getPagingSortingParams(params);
    const pagination: TablePaginationConfig = {
        ...defaultTablePagination,
        current:
            pageIndexParam && parseInt(pageIndexParam)
                ? parseInt(pageIndexParam)
                : defaultTablePagination.defaultCurrent,
        pageSize:
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
        total: props.totalCount,
    };

    return (
        <Form ref={formRef} onFinish={handleFormSubmit} preserve={false}>
            <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <TabPane tab={translations.payrolls.accounting} key="1">
                    {activeTabKey === '1' && (
                        <Table
                            className="ant-table-slim"
                            locale={getTableLocale()}
                            columns={getTableColumns()}
                            dataSource={props.employeePayrolls}
                            loading={props.loading}
                            rowKey={(record: EmployeePayrollVm): string =>
                                record.employee?.id?.toString() || '0'
                            }
                            rowClassName={(record: EmployeePayrollVm) =>
                                editingItem?.employee?.id === record?.employee?.id
                                    ? EDITING_ROW_CLASS
                                    : ''
                            }
                            onChange={handleTableChange}
                            pagination={pagination}
                            bordered
                            size="small"
                            summary={() => {
                                return (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={14} />
                                        <Table.Summary.Cell index={14} align="right">
                                            <b>UKUPNO</b>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={15} align="right">
                                            <b>
                                                {formatPrice(
                                                    props.employeePayrolls.reduce(
                                                        (sum, item) =>
                                                            sum + (item.payrollItem?.total ?? 0),
                                                        0
                                                    )
                                                )}
                                            </b>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                );
                            }}
                        />
                    )}
                </TabPane>
                <TabPane tab={translations.payrolls.administration} key="2">
                    {activeTabKey === '2' && (
                        <Table
                            className="ant-table-slim"
                            locale={getTableLocale()}
                            columns={getAdministartionTableColumns()}
                            dataSource={props.employeePayrolls}
                            loading={props.loading}
                            rowKey={(record: EmployeePayrollVm): string =>
                                record.employee?.id?.toString() || '0'
                            }
                            rowClassName={(record: EmployeePayrollVm) =>
                                editingItem?.employee?.id === record?.employee?.id
                                    ? EDITING_ROW_CLASS
                                    : ''
                            }
                            onRow={(record: EmployeePayrollVm) => ({
                                onDoubleClick: () => {
                                    if (
                                        record.payrollItem &&
                                        Object.keys(record.payrollItem).length > 0
                                    ) {
                                        handleEdit(record);
                                    }
                                },
                            })}
                            onChange={handleTableChange}
                            pagination={pagination}
                            bordered
                            size="small"
                            summary={() => {
                                return (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={8} />
                                        <Table.Summary.Cell index={8} align="right">
                                            <b>UKUPNO</b>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={9} align="right">
                                            <b>
                                                {formatPrice(
                                                    props.employeePayrolls.reduce(
                                                        (sum, item) =>
                                                            sum + (item.payrollItem?.total ?? 0),
                                                        0
                                                    )
                                                )}
                                            </b>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                );
                            }}
                        />
                    )}
                </TabPane>
            </Tabs>
        </Form>
    );
};

export default PayrollTable;
